<template>
  <div class=" lg:tw-hidden">
    <v-bottom-navigation v-model="value" fixed color="#008EEF">
      <v-btn v-for="(data,i) in menu" :key="i" :value="data.name" :exact="data.exact" :to="data.route">
        <vue-feather :type="data.icon"></vue-feather>
      </v-btn>
    </v-bottom-navigation>
    <v-btn @click="$router.push({name : 'Job-stepOne'})"
        class="float-btn"
        fab
        :color="$vuetify.theme.themes.light.enumBlue.enumBlue"
    >
      <v-icon color="white">mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "BottomNavigation",
  data(){
    return{
      value:'',
    }
  },
    props: {
      menu: {
        type: Array,
        default: function () {
          return [
            {
              id: 1,
              name: 'home',
              route: '/dashboard',
              icon: 'home',
              exact: true
            },
            {
              id: 2,
              name: 'candidate',
              route: '/dashboard/candidate',
              icon: 'users',
              exact: true
            },
            {
              id: 3,
              name: 'jobs',
              route: '/dashboard/jobs',
              icon: 'briefcase',
              exact: true
            }, {
              id: 4,
              name: 'talent pool',
              route: '/dashboard/talent-pool',
              icon: 'box',
              exact: true
            },
          ]
        }
      }
    }
}
</script>

<style scoped>
.float-btn{
position: fixed;
  left: 50%;
transform: translate(-50%, -50%);
  z-index: 5;
  bottom: 0;
  cursor: pointer;
}

</style>
